import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: 'create-issue',
    canActivate: [AuthGuard],
    //canActivate:[AuthGuard],
    loadChildren: './create-issue/create-issue.module#CreateIssueModule'
  },
  {
    path: 'ticket-list',
    canActivate: [AuthGuard],
    //canActivate:[AuthGuard],
    loadChildren: './dashboard/dashboard.module#DashboardModule'

  },
  {
    path: 'admindashboard',
    canActivate: [AuthGuard],
    loadChildren: './admindashboard/admindashboard.module#AdmindashboardModule'
  },
  {
    path: 'seo',
    canActivate: [AuthGuard],
    loadChildren: './seo/seo.module#SeoModule'

  },

  // New SEO module added here
  {
    path: 'newseo',
    canActivate: [AuthGuard],
    loadChildren: './newseo/newseo.module#NewseoModule'
  },

  // {
  //   path: 'sm',
  //   canActivate: [AuthGuard],
  //   loadChildren: './sm/sm.module#SmModule'

  // },
  {
    path: 'smNew',
    canActivate: [AuthGuard],
    loadChildren: './smNew/smNew.module#SmNewModule'
  },

  {
    path: 'support',
    canActivate: [AuthGuard],
    loadChildren: './am/am.module#AmModule'

  },

  {
    path: 'rm',
    canActivate: [AuthGuard],
    loadChildren: './rm/rm.module#RmModule'

  },

  {
    path: 'rm/campaign',
    canActivate: [AuthGuard],
    loadChildren: './rm/rm.module#RmModule'

  },
  {
    path: 'rm/report',
    canActivate: [AuthGuard],
    loadChildren: './rm/rm.module#RmModule'
  },

  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: './overview/overview.module#OverviewModule'

  },

  {
    path: 'mypl',
    canActivate: [AuthGuard],
    //canActivate:[AuthGuard],
    loadChildren: './mypl/mypl.module#MyplModule'
  },

  {
    path: 'pbform',
    canActivate: [AuthGuard],
    //canActivate:[AuthGuard],
    loadChildren: './pbform/pbform.module#PbformModule'
  },

  {
    path: 'webadmin',
    canActivate: [AuthGuard],
    //canActivate:[AuthGuard],
    loadChildren: './webadmin/webadmin.module#WebadminModule'
  },
  {
    path: 'targeting',
    canActivate: [AuthGuard],
    //canActivate:[AuthGuard],
    loadChildren: './targeting/targeting.module#TargetingModule'
  },
  {
    path: 'forgot-password',
    loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule'
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: './settings/settings.module#SettingsModule'
  },
  // {
  //   path: 'notification',
  //   canActivate: [AuthGuard],
  //   loadChildren: './notification/notification.module#NotificationModule'
  // },

  {
    path: '',
    loadChildren: './login/login.module#LoginModule'
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'reset/:id/:token',
    component: ResetPasswordComponent,
    // loadChildren: './reset-password/reset-password.module#ResetPasswordModule'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }