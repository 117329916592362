import { Component } from '@angular/core';
import{DataService} from './data.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
login:string="yes";

constructor(private dataService:DataService) { }

  title = 'app Works';
}
