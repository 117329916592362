import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
declare var $:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentyear
  constructor(private dataservice:DataService) { }

  ngOnInit() {
    this.currentyear =new Date().getFullYear();
    $(".navbar-toggle").click(function(){
    console.log("I m in");
}); 
  	$(window).scroll(function (event) {
    var scroll = $(window).scrollTop();
    if(scroll>=60)
    {
    	$("#nb").addClass('sticked');
    }
    else
    {
    	$("#nb").removeClass('sticked');
    }
    // Do something
});
  }

}
