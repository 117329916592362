import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ToasterService } from '../toaster.service';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  error_msg: string;
  client_id: string;
  token: string;

  constructor(private dataService: DataService, private router: Router, private toastr: ToasterService, private route: ActivatedRoute) { }

  ngOnInit() {
    // Get Params from URL Route
    this.client_id = this.route.snapshot.paramMap.get('id');
    this.token = this.route.snapshot.paramMap.get('token');
  }

  resetpassword(formData) {
    if (!formData.password || !formData.confirm_password) {
      this.error_msg = "Password and confirm password cannot be empty";
    }
    else {
      this.dataService.isload = true;
      if (formData.password === formData.confirm_password && this.client_id && this.token) {
        let params = {
          id: this.client_id,
          token: this.token,
          password: formData.password
        }

        this.dataService.resetPassword(params)
          .subscribe(res => {
            this.dataService.isload = false;
            this.error_msg = "";
            this.toastr.showSuccess(res['message']);
            this.router.navigate(['/']);
          }, err => {
            this.dataService.isload = false;
            this.toastr.showError(err.error['message'])
          })
      }
      else {
        this.dataService.isload = false;
        this.error_msg = "Password and confirm password does not match";
      }
    }
  }

}
