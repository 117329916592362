import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router, RouterModule ,Event, NavigationStart,} from '@angular/router';
import {NgForm} from '@angular/forms';
import {ToasterService} from '../toaster.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  profileSetting: FormGroup;
  submitted: boolean = false;
  profileImage: any;
  imagefile
  fileToUpload
  loaderProfile : boolean = false;
  imageURL: any;
  loaderActive: boolean = false;
mypl:string=sessionStorage.getItem('mypl');
admin_key:string=sessionStorage.getItem('admin_key');
eyecareUrl:string=sessionStorage.getItem('portalUrl');
eyecare:string=sessionStorage.getItem('is_Eyecare'); 
message_password:string="";
errorMessage_password:string="";
admin_visit:number=0;
rm_visit:number=0;
seo_tab:number=0;
show_newseo: boolean = false;
show_newsm: boolean = false;
menu_hit:string="close";
  constructor(private dataservice:DataService,private router:Router ,private FormBuilder: FormBuilder,private toastr: ToasterService) {
    this.profileSetting = this.FormBuilder.group({
      profileImg: ['', []],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
    })
    // **************reload the image after updated****************
    // this.router.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationStart) {
    //    if(event.url== "/dashboard"){
    //     if( sessionStorage.getItem("imageProfile") !=''  && sessionStorage.getItem("imageProfile") != 'null'){
    //       this.imageURL = sessionStorage.getItem("imageProfile")
    //       this.profileImage = sessionStorage.getItem("imageProfile")
    //     }else{
    //       this.imageURL = "assets/dist/img/avatar.png";
    //       this.profileImage = "assets/dist/img/avatar.png";
    //     }
    //    }
    //   }
    // }); 
 
    if(sessionStorage.getItem("from_am") && sessionStorage.getItem("from_am") == "1") {
      this.show_newseo = true;
    }
     // new social media 
    if(sessionStorage.getItem("from_newsm") && sessionStorage.getItem("from_newsm") == "1") {
      this.show_newsm = true;
    }
    
   }
click_m()
{ 
  $("body").removeClass('side-right');
  if ($('.navbar-collapse').hasClass('in'))
 { 
   //console.log("hello1")
   //$("body").removeClass('side-right');
   $(".navbar-collapse").removeClass('collapsing');
   $(".navbar-collapse").removeClass('in');
   
   
   return false;
  }
  else
  {
    //console.log("h1");
    $(".navbar-collapse").removeClass('in');
   $(".navbar-collapse").addClass('collapsing');
   
   
   
   return false;
  }

}

change_password(param,str1:NgForm)
{
this.dataservice.isload=true;
str1.reset();

this.dataservice.change_password(param.oldpassword,param.newpassword,param.newpassword1).
subscribe(data=>
{
  
  this.message_password=data['message'];
  //console.log(data['data']);
  if(typeof data['data']['auth_token'] !== 'undefined'){
  sessionStorage.setItem("auth_token", data['data']['auth_token']);
  }
  this.dataservice.isload=false;
},err => {
  //this.errorMessage="Some Internal Issues While Making Api Call";
  this.errorMessage_password="Some Internal Issues While Making Api Call";
  this.dataservice.isload=false;
});
}

logout()
{
  
  sessionStorage.setItem("client_id", '');
  sessionStorage.setItem("profile_id", '');
  sessionStorage.setItem("username", '');
  sessionStorage.setItem("firstName", '');
  sessionStorage.setItem("lastName", '');
  sessionStorage.setItem("email", '');
  sessionStorage.setItem("phoneNo", '');
  sessionStorage.setItem("location", '');
  sessionStorage.setItem("account_manager_email", '');
  sessionStorage.setItem("account_manager_id", '');
  sessionStorage.setItem("account_manage_name", '');
  sessionStorage.setItem("account_manage_profile_image", '');
  sessionStorage.setItem("client_profile_image", '');
  sessionStorage.setItem("display_name", '');
  sessionStorage.setItem("am_mobile", '');
  sessionStorage.setItem("auth_token", '');
  sessionStorage.setItem("entity_type", '');
  sessionStorage.setItem("ga_view_id", '');
  sessionStorage.setItem("website", '');
  sessionStorage.setItem("is_web", '');
  sessionStorage.setItem("twitter_id", '');
  sessionStorage.setItem("linkedIn_id", '');
  sessionStorage.setItem("fb_page_id", '');
  sessionStorage.setItem("jira_user", '');
  sessionStorage.setItem("is_seo", '');
  sessionStorage.setItem("is_newseo", '');  // new seo report
  sessionStorage.setItem("is_rm", '');
  sessionStorage.setItem("is_sm", '');
  sessionStorage.setItem("is_newsm", '');   //new social media
  sessionStorage.setItem("sf_id", '');
  sessionStorage.setItem("seo_state", '');
  sessionStorage.setItem("state_longitude", '');
  sessionStorage.setItem("state_latitude", '');
  sessionStorage.setItem("mypr_access_key", '');
  
  sessionStorage.setItem("is_pageviews",'');
  sessionStorage.setItem("gmb",'');
  sessionStorage.setItem("average_patient_spend",'');
  sessionStorage.setItem("lead_conversion_rate",'');

  sessionStorage.setItem("web_admin_name",'');
  sessionStorage.setItem("admin_key",'');
  sessionStorage.setItem("mypl",'');
  sessionStorage.setItem("pb_client_id",''); 
  sessionStorage.setItem("from_am", "");
  sessionStorage.setItem("isLogedin",'');
  // sessionStorage.setItem("imageProfile",'');
  this.dataservice.isUserLogedin=false;
 this.router.navigate(['/']);
}
  ngOnInit() {
    // ****************Image upload code
    // if( sessionStorage.getItem("imageProfile") !=''  && sessionStorage.getItem("imageProfile") != 'null' && sessionStorage.getItem("imageProfile") != 'undefined'){
    //   this.imageURL = sessionStorage.getItem("imageProfile")
    //   this.profileImage = sessionStorage.getItem("imageProfile")
    // }else{
    //   this.imageURL = "assets/dist/img/avatar.png";
    //   this.profileImage = "assets/dist/img/avatar.png";
    // }
    if(sessionStorage.getItem('actwebadmin')=='true')
    {
      //this.dataservice.admin_visit=1;
    }
    
    if(sessionStorage.getItem('actrm')=='true')
    {
      //this.dataservice.rm_visit=1;
    }

    if(sessionStorage.getItem('admin_key')=='')
    {
      this.dataservice.admin_visit=1;
    }
    
    if(sessionStorage.getItem('is_rm')=='0')
    {
      this.dataservice.rm_visit=1;
    }

    if(sessionStorage.getItem('ga_view_id')!='')
    {
      this.dataservice.seo_tab=1;
    }
    
    //this.adminurl=this.dataservice.website+"/pbvault_signin/verify.php/?key="+this.dataservice.admin_key+"&path="+this.dataservice.web_admin_name+"&tk="+(Math.floor(Math.random()*90000) + 10000);
    //this.rmurl="https://expertpracticemarketing.com/myprac1/public/api/pb-integration/get/param/safari-auth/access_key/"+this.dataservice.mypr_access_key;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.uploadfile1(this.fileToUpload, event.target.result)
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  uploadfile1(file, base64) {
    this.imagefile = {
      name: file.name,
      type: file.type,
      mode: "attach",
      imageBase64: base64
    }
    this.loaderActive = true;
    this.dataservice.imageUploadS3({ image: this.imagefile }).subscribe(data => {
      this.imageURL = data
      this.imageURL = this.imageURL.data
      this.loaderActive = false;
    }, err => {
      this.loaderActive = false;
      console.log(err)
    });
  }
  get f() { return this.profileSetting.controls; }

  setting() {
    this.profileSetting.controls['first_name'].setValue(sessionStorage.getItem("firstName"))
    this.profileSetting.controls['last_name'].setValue(sessionStorage.getItem("lastName"))
  }
  updateProfile() {
    this.submitted = true
    if (this.profileSetting.valid) {
      this.loaderProfile = true;
      this.profileSetting.value.client_id = sessionStorage.getItem("client_id")
      this.profileSetting.value.auth_token = sessionStorage.getItem("auth_token")
      if (this.imageURL == "assets/dist/img/avatar.png") {
        this.profileSetting.value.profileImg = '';
      } else {
        this.profileSetting.value.profileImg = this.imageURL;
      }
      this.dataservice.profileUpdate(this.profileSetting.value).subscribe(data => {
        if (data['status'] == 1) {
          this.toastr.showSuccess("Profile Updated");
          sessionStorage.setItem("firstName", this.profileSetting.value.first_name);
          sessionStorage.setItem("lastName", this.profileSetting.value.last_name);
          sessionStorage.setItem("imageProfile", this.profileSetting.value.profileImg);
          if (this.profileSetting.value.profileImg != '') {
            this.profileImage = this.profileSetting.value.profileImg;
          }
          this.dataservice.client_name = sessionStorage.getItem("firstName") + " " + sessionStorage.getItem("lastName");
        } else {
          this.toastr.showError("Unable to save profile data");
        }
        this.loaderProfile = false;
        $('#setting').modal('hide');
      }, err => {
        this.loaderProfile = false;
        console.log(err)
      });
    }
  }
}
