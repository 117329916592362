import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {DataService} from './data.service';
import{ Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
	constructor(private dataservice:DataService,private router:Router)
	{
	
	}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):boolean {
    //this.router.navigate(['/']);
    //console.log("You are not authenticated user !!");
    if(sessionStorage.getItem("isLogedin")=='true')
    {
    return true;
    }
    else
    {
      this.router.navigate(['/']);
      return false;
    }
  }
}
