// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://dashboard.practicebuilders.com:3000/',
  //baseUrlForGa: 'http://dev.test.com/googleanyalitic/HelloAnalytics.php',
 // baseUrl:  'http://localhost:3000/',
  // baseUrlApi: 'http://admin.myormanager.com/'
  // baseUrl: 'http://admin.myormanager.com:3000/',
   baseUrlApi: 'https://dashboard.practicebuilders.com/',
   baseUrlApiBeanStalk :'https://pbaccessapi.yourpracticeapps.com/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
